import Login from 'components/Login';
import config from 'config';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Register = () => {
  const dispatch = useAppDispatch();
  const [isForm, setIsForm] = useState(true);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  
  const confirmCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/signup/confirm`).post({
      email: email,
      code: code,
    });
    
    if (result.code === 200) {
      Cookies.set('refreshToken', result.data.refreshToken, { expires: 365 });
      Cookies.set('accessToken', result.data.accessToken, { expires: 365 });

      dispatch(successAlert('ثبت نام موفقیت آمیز بود'));

      window.location.reload();
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
      dispatch(hideLoading());
    }
  }

  const signup = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/signup`).post({
      email: email,
      password: password,
      repassword: repassword,
    });

    if (result.code === 200) {
      setIsForm(false);
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='popup container'>
      <div className='backParent' onClick={() => dispatch(hideDrawer())}>
        <div className='backIcon'></div>
      </div>
      <h3>ثبت نام</h3>
      {isForm ? (
        <div className='form'>
          <div className='inputParent'>
            <input type='text' placeholder='ایمیل' onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className='inputParent'>
            <input type='password' autoComplete='new-password' placeholder='رمز عبور' onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className='inputParent'>
            <input type='password' autoComplete='new-password' placeholder='تکرار رمز عبور' onChange={(e) => setRepassword(e.target.value)} />
          </div>
          <span className='mainButton' onClick={() => signup()}>ثبت نام کنید</span>
        </div>
      ) : (
        <div className='form'>
          <div className='inputParent'>
            <input type='text' name='code' placeholder='کد تایید' value={code} onChange={(e) => setCode(e.target.value)} />
          </div>
          <span className='mainButton' onClick={() => confirmCode()}>بررسی کد</span>
        </div>
      )}
      <span className='goLogin' onClick={() => { dispatch(hideDrawer()); setTimeout(() => dispatch(bottomDrawer(<Login />)), 350) } }>ثبت نام کرده اید؟ اینجا وارد شوید</span>
    </div>
  );
}

export default Register;
