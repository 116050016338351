import Login from 'components/Login';
import Register from 'components/Register';
import config from 'config';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const ForgetPassword = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForm, setIsForm] = useState(true);
  const [code, setCode] = useState('');

  const confirmCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/forgetPassword/confirm`).post({
      email: email,
      code: code,
      newPassword: password,
    });
    
    if (result.code === 200) {
      dispatch(successAlert('رمز عبور با موفقیت تغییر کرد'));
      dispatch(hideDrawer());
      setTimeout(() => dispatch(bottomDrawer(<Login />)), 300);
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  }

  const reset = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/forgetPassword`).post({
      email: email,
    });
    
    if (result.code === 200) {
      setIsForm(false);
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='popup container'>
      <div className='backParent' onClick={() => dispatch(hideDrawer())}>
        <div className='backIcon'></div>
      </div>
      <h3>فراموشی رمز</h3>
      {isForm ? (
        <div className='form'>
          <div className='inputParent'>
            <input type='text' autoComplete='off' placeholder='ایمیل' onChange={(e) => setEmail(e.target.value)} />
          </div>
          <span className='mainButton' onClick={() => reset()}>ارسال کد تایید</span>
        </div>
      ) : (
        <>
          <div className='form'>
            <div className='inputParent'>
              <input type='text' name='code' placeholder='کد تایید' value={code} onChange={(e) => setCode(e.target.value)} />
            </div>
            <div className='inputParent'>
              <input type='password' autoComplete='new-password' placeholder='رمز عبور جدید' onChange={(e) => setPassword(e.target.value)} />
            </div>
            <span className='mainButton' onClick={() => confirmCode()}>بررسی کد</span>
          </div>
        </>
      )}
      <span className='goLogin' onClick={() => { dispatch(hideDrawer()); setTimeout(() => dispatch(bottomDrawer(<Register />)), 350) } }>حساب کاربری ندارید؟ اینجا کلیک کنید</span>
    </div>
  );
}

export default ForgetPassword;
