const config = {
  apiUrl: process.env.REACT_APP_API_URL!,
  errorCode: {
    code_10001: 'این ایمیل قبلا ثبت شده است',
    code_10002: 'ایمیل صحیح نمی باشد',
    code_10003: 'رمز عبور خالی است',
    code_10004: 'تکرار رمز عبور خالی است',
    code_10009: 'کد صحیح نمی باشد',
    code_10010: 'کد اشتباه می باشد',
    code_10011: 'ایمیل یا رمز ورود صحیح نمی باشد',
    code_10022: 'موجودی شما کم می باشد',
    code_10024: 'آدرس دریافت کننده صحیح نیست',
    code_10039: 'فاصله دریافت برای یک دریافت کننده حداقل ۵ دقیقه می باشد',
    code_20000: 'درخواست شما به دلیل عدم انجام از لیست خارج شد. با مدیریت تماس بگیرید',
    code_500: 'خطای داخلی. مجدد سعی نمایید',
    code_502: 'خطای درگاه. مجدد سعی نمایید',
    code_404: 'درخواست شما پیدا نشد',
    code_401: 'شما به این آدرس دسترسی ندارید',
    errorFound: 'خطای ناشناخته! مجدد سعی نمایید',
  } as any,
};

export default config;
