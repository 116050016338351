import { useQRCode } from 'next-qrcode';
import { useAppDispatch, useAppSelector } from 'reducer';
import { successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';

const Profile = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const { Image } = useQRCode();
  const useUser = useAppSelector(state => state.user);
  
  const copy = () => {
    navigator.clipboard.writeText(useUser.profile.trx.address);
    dispatch(successAlert('کپی شد'));
  }

  return (
    <div className='popup container'>
      <div className='backParent' onClick={() => dispatch(hideDrawer())}>
        <div className='backIcon'></div>
      </div>
      <h3>پروفایل</h3>
      <div className='form'>
        {/* <div className='mainButton yourBalance'>Your Balance: {useUser.profile.balance.free} TRX</div> */}
        <div className='inputParent'>
          <span>واریز</span>
          <div className='publicCopy' onClick={() => copy() }>
            {/* <i>{useUser.profile.trx.address}</i> */}
            {/* <Image text={useUser.profile.trx.address} options={{ type: 'image/jpeg', quality: 1, margin: 1, width: 60 }} /> */}
          </div>
        </div>
      </div>
      {/* <div className='mainButton' onClick={() => copy() }>Copy Address</div> */}
    </div>
  );
}

export default Profile;
