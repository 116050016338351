import Support from 'components/Support';
import { useAppDispatch } from 'reducer';
import { bottomDrawer } from 'reducer/slices/drawerSlice';

const Bottom = () => {
  const dispatch = useAppDispatch();

  return (
    <div className='bottom'>
      <div className='text'>تمامی حقوق برای این وب سایت محفوظ می باشد</div>
      <div className='support' onClick={() => dispatch(bottomDrawer(<Support />))}></div>
    </div>
  );
};

export default Bottom;
