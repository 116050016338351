import ForgetPassword from 'components/ForgetPassword';
import Register from 'components/Register';
import config from 'config';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Login = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForm, setIsForm] = useState(true);
  const [code, setCode] = useState('');

  const confirmCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/signin/confirm`).post({
      email: email,
      code: code,
    });
    
    if (result.code === 200) {
      Cookies.set('refreshToken', result.data.refreshToken, { expires: 365 });
      Cookies.set('accessToken', result.data.accessToken, { expires: 365 });

      dispatch(successAlert('ورود موفقیت آمیز بود'));

      window.location.reload();
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
      dispatch(hideLoading());
    }
  }

  const singin = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/auth/signin`).post({
      email: email,
      password: password,
    });
    
    if (result.code === 200) {
      if (result.data.accessToken) {
        Cookies.set('refreshToken', result.data.refreshToken, { expires: 365 });
        Cookies.set('accessToken', result.data.accessToken, { expires: 365 });

        dispatch(successAlert('ورود موفقیت آمیز بود'));

        window.location.reload();
      } else {
        setIsForm(false);
      }
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='popup container'>
      <div className='backParent' onClick={() => dispatch(hideDrawer())}>
        <div className='backIcon'></div>
      </div>
      <h3>ورود به سایت</h3>
      {isForm ? (
        <div className='form'>
          <div className='inputParent'>
            <input type='text' autoComplete='off' placeholder='ایمیل' onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className='inputParent'>
            <input type='password' autoComplete='new-password' placeholder='رمز عبور' onChange={(e) => setPassword(e.target.value)} />
          </div>
          <span className='mainButton' onClick={() => singin()}>وارد شوید</span>
          {/* <span className='mainButton feeData' onClick={() => singin()}>Login Now</span> */}
          {/* <span className='goLogin' onClick={() => { dispatch(hideDrawer()); setTimeout(() => dispatch(bottomDrawer(<ForgetPassword />)), 300) } }>Forget Password</span> */}
        </div>
      ) : (
        <div className='form'>
          <div className='inputParent'>
            <span>Confirm Code</span>
            <input type='text' name='code' placeholder='کد تایید' value={code} onChange={(e) => setCode(e.target.value)} />
          </div>
          <span className='mainButton' onClick={() => confirmCode()}>بررسی کد</span>
        </div>
      )}
      <span className='goLogin' onClick={() => { dispatch(hideDrawer()); setTimeout(() => dispatch(bottomDrawer(<Register />)), 350) } }>ثبت نام نکرده اید؟ اینجا کلیک کنید</span>
      <span className='goLogin' onClick={() => { dispatch(hideDrawer()); setTimeout(() => dispatch(bottomDrawer(<ForgetPassword />)), 300) } }>یا رمز عبور را فراموش کرده اید؟</span>
    </div>
  );
}

export default Login;
