import config from 'config';
import Cookies from 'js-cookie';
import { successAlert } from 'reducer/slices/alertSlice';
import { updateWallet } from 'reducer/slices/userSlice';
import { AppDispatch } from 'reducer/store';
import { Socket, io } from 'socket.io-client';

let socket: Socket;

const connect = async (dispatch: AppDispatch) => {
  if (!socket?.connected) {
    const accessToken = Cookies.get('accessToken');
    socket = io(config.apiUrl, {
      extraHeaders: {
        authorization: 'Bearer ' + accessToken,
      },
    });

    socket.on('connect', () => {
      console.log('socket', 'Connected');
    });

    socket.on('disconnect', () => {
      console.log('socket', 'Disconnected');
      setTimeout(() => {
        connect(dispatch);
      }, 1000);
    });

    socket.on('connect_failed', () => {
      console.log('socket', 'Failed');
    }) 

    socket.on('connect_error', (e) => {
      console.log('socket', 'Error: ' + e);
    })

    socket.on('balance', (result: any) => {
      dispatch(updateWallet(result)); 
      dispatch(successAlert('موجودی شما بروز شد'));
    });
  }
}

export default {
  connect,
};
