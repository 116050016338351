import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import gather from 'tools/gather';

export const getCoin = createAsyncThunk('getCoin', async () => {
  return await gather(`${config.apiUrl}/v1/coin`).get();
});

export const getSetting = createAsyncThunk('getSetting', async () => {
  return await gather(`${config.apiUrl}/v1/setting`).get();
});

export const loadingSlice = createSlice({
  name: 'data',
  initialState: {
    coin: {} as any,
    setting: {} as any,
  },
  reducers: {
    //
  }, extraReducers: (builder) => {
    builder
      .addCase(getCoin.fulfilled, (state, action) => {
        state.coin = action.payload.data;
      })
      .addCase(getSetting.fulfilled, (state, action) => {
        state.setting = action.payload.data;
      });
  }
})

export default loadingSlice.reducer;
