import Bottom from 'layouts/Bottom';
import Header from 'layouts/Header';
import Form from './Form';

const Home = () => {
  return (
    <>
      <Header />
      <div className='container mainUp'>
        {/* <div className='text'>Tron Energy Market offers the ability to profit more from your tron staking rewards than simply voting alone.</div> */}
        <Form />
        {/* <History /> */}
      </div>
      <Bottom />
    </>
  );
};

export default Home;
