import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import gather from '../../tools/gather';

export const getProfile = createAsyncThunk('getProfile', async () => {
  return await gather(`${config.apiUrl}/v1/user`, true).get();
});

export const loadingSlice = createSlice({
  name: 'user',
  initialState: {
    profile: {
      email: '',
      balance: {} as any,
      trx: {} as any,
    },
  }, 
  reducers: { 
    updateWallet: (state, action) => { 
      state.profile.balance = action.payload; 
    },
  }, extraReducers: (builder) => {
    builder
      .addCase(getProfile.fulfilled, (state, action) => {
        state.profile = action.payload.data as any;
      });
  }
});

export const { updateWallet } = loadingSlice.actions;
export default loadingSlice.reducer;
