import { useAppDispatch } from 'reducer';
import { hideDrawer } from 'reducer/slices/drawerSlice';

const Support = () => {
  const dispatch = useAppDispatch();

  return (
    <div className='popup container'>
      <div className='backParent' onClick={() => dispatch(hideDrawer())}>
        <div className='backIcon'></div>
      </div>
      <h3>پشتیبانی</h3>
      <div className='form'>
        <div className='noItem'>
          کانال اطلاع رسانی:
          <br />
          <br />
          <a href='https://t.me/HotVoucher_Supp' target='_blank'>
            HotVoucher_Supp@
          </a>
          <br />
          <br />
          <br />
          <br />
          پشتیبانی:
          <br />
          <br />
          <a href='https://t.me/HotVoucher_Supp' target='_blank'>
            HotVoucher_Supp@
          </a>
        </div>
      </div>
    </div>
  );
};

export default Support;
