import { createSlice } from '@reduxjs/toolkit';

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    visible: false,
    content: null,
    position: 'bottom' as any,
    entry: 'bottom',
  },
  reducers: {
    bottomDrawer: (state, action) => {
      state.visible = true;
      state.content = action.payload;
      state.entry = 'bottom';
      state.position = 'bottom';
    },
    topDrawer: (state, action) => {
      state.visible = true;
      state.content = action.payload;
      state.entry = 'top';
      state.position = 'top';
    },
    hideDrawer: state => {
      state.visible = false;
    },
  }
})

export const { bottomDrawer, topDrawer, hideDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
