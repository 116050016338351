import { Alert, Backdrop, CircularProgress, Drawer, Snackbar } from '@mui/material';
import Home from 'components/Home';
import config from 'config';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducer';
import { hideAlert } from 'reducer/slices/alertSlice';
import { getCoin, getSetting } from 'reducer/slices/dataSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { getProfile } from 'reducer/slices/userSlice';
import socket from 'socket';
import gather from 'tools/gather';

const App = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const useLoading = useAppSelector((state) => state.loading);
  const useDrawer = useAppSelector((state) => state.drawer);
  const useAlert = useAppSelector((state) => state.alert);

  const init = async () => {
    dispatch(showLoading());

    await Promise.all([dispatch(getCoin()), dispatch(getSetting())]);

    const refreshToken = Cookies.get('refreshToken');
    if (refreshToken) {
      const result = await gather(`${config.apiUrl}/v1/auth/refreshToken`).get({
        authorization: `Bearer ${refreshToken}`,
      });

      if (result.code === 200) {
        Cookies.set('accessToken', result.data.accessToken, { expires: 365 });

        await Promise.all([dispatch(getProfile())]);

        socket.connect(dispatch);
      }
    }

    dispatch(hideLoading());
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <BrowserRouter basename='/'>
      <div className='app'>
        <Drawer
          PaperProps={{ sx: { height: '100%' } }}
          anchor={useDrawer.position}
          open={useDrawer.visible}
          onClose={() => dispatch(hideDrawer())}
        >
          {useDrawer.content}
        </Drawer>
        <Backdrop
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            color: '#222',
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
          open={useLoading.visible}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
        <Snackbar
          open={useAlert.visible}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={2000}
          onClose={() => dispatch(hideAlert())}
        >
          <Alert
            onClose={() => dispatch(hideAlert())}
            variant='filled'
            severity={useAlert.severity}
            sx={{ width: '100%', mb: '10px' }}
          >
            {useAlert.text}
          </Alert>
        </Snackbar>
        {!isLoading && <Home />}
      </div>
    </BrowserRouter>
  );
};

export default App;
