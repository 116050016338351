import Profile from 'components/Profile';
import Register from 'components/Register';
import { useAppDispatch, useAppSelector } from 'reducer';
import { bottomDrawer } from 'reducer/slices/drawerSlice';

const Header = () => {
  const dispatch = useAppDispatch();
  const useUser = useAppSelector(state => state.user);
  
  return (
    <div className='topMenu'>
      <div className='container'>
        <div className='logo'>
          <span></span>
          <h1>خرید سریع<b>ارسال و دریافت سریع</b></h1>
        </div>
        <div className='profile' onClick={() => dispatch(bottomDrawer(useUser.profile.email ? <Profile /> : <Register />))}>{useUser.profile.email ? 'پروفایل کاربری' : 'ورود / ثبت نام'}</div>
        {/* <div className='menu' onClick={() => dispatch(topDrawer(<Menu />))}></div> */}
      </div>
    </div>
  );
}

export default Header;
