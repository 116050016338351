import config from 'config';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'reducer';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import gather from 'tools/gather';

let timer: any = null;
let end = moment().add(5, 'minutes').unix();
// let end = moment().add(10, 'seconds').unix();

const Checker = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const [result, setResult] = useState<any>();

  const init = async () => {
    if (end < moment().unix()) {
      setResult({
        result: {
          error: 20000,
        },
      });
      return;
    }

    const res = await gather(`${config.apiUrl}/v1/express/checker/${data.id}`).get();
    if (res.code === 200) {
      setResult(res.data);
    } else {
      timer = setTimeout(() => {
        init();
      }, 5000);
    }
  };

  useEffect(() => {
    init();

    return () => {
      window.history.pushState(null, '', window.location.pathname);
      clearTimeout(timer);
      timer = null;
    };
  }, []);
  console.log('result.receiver', result?.receiver?.length);
  return (
    <div className='popup container'>
      <div className='backParent' onClick={() => dispatch(hideDrawer())}>
        <div className='backIcon'></div>
      </div>
      <h3>شماره درخواست: {data.id}</h3>
      <div className='form'>
        {result ? (
          result?.result?.error ? (
            <div className='wrongData'>
              {config.errorCode['code_' + result.result.error] || config.errorCode['errorFound']}
            </div>
          ) : result.side === 'receive' ? (
            <div className='getData'>
              مبلغ{' '}
              <b>
                {result?.result?.receive} {result?.result?.currency}
              </b>{' '}
              با موفقیت به دریافت شد
              <br />
              <br />
              کد پیگیری:
              <br />
              <br />
              <b>{result?.result?.code}</b>
            </div>
          ) : result?.receiver ? (
            <div className='getData'>
              مبلغ{' '}
              <b>
                {result?.result?.total} {result?.result?.currency}
              </b>{' '}
              با موفقیت به حساب {result?.receiver} واریز شد
            </div>
          ) : (
            <div className='getData'>
              cVoucher با موفقیت ساخته شد
              <br />
              <br /> <b>{result?.result?.code}</b>{' '}
            </div>
          )
        ) : (
          <>
            {data.wallet && (
              <div className='noItem'>
                مبلغ مورد نظر را به آدرس کیف پول زیر واریز نمایید:
                <br />
                <br />
                {data.wallet}
              </div>
            )}
            <span className='noItem'>
              در حال بررسی...
              <br />
              <br />
              لطفا تا پایان بررسی درخواست، این صفحه را نبندید
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default Checker;
